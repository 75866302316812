// xs-max set column
.set-column-height-xs-max
  &__top_15
    @media (max-width: $breakpoint-xs-max)
      padding-top: 15px

// sm-min set column
.set-column-height-sm-min
  &__left_15
    @media (min-width: $breakpoint-sm-min)
      padding-left: 15px
  &__left_5
    @media (min-width: $breakpoint-sm-min)
      padding-left: 5px
