@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'

@import '../../css/quasar.variables.sass'

.sticky__content
  z-index: 2000

.sticky
  background-color: $ashes
  transition: box-shadow .2s ease
  &--visible
    box-shadow: 0 4px 4px -3px rgba(0, 0, 0, .2)
  &mobile
    background-color: $ashes

