/**
 * Climatools button styles
 * This is the base of button styles for the climatools application.
 */
.ct_button
    margin: 0
    padding: 0
    width: 100%
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1)

    // Small button
    &__small
        font-size: 8px
        padding: 6px .6rem 6px !important

    // Medium button (medium is the most used general button type in the application).
    &__medium
        font-size: 13px
        padding: 7px .85rem 8px .85rem !important

    &__big
        font-size: 17px
        padding: 9px .85rem 8px .85rem !important

    // This is the general filtering button used within the overview pages.
    &__filter_btn
        border-radius: 3px
        border: 1px #c8c8c8 solid
        &:hover
            border: 1px $primary solid
            color: white
            background-color: $primary
        padding: .4rem .7rem .5rem
        max-height: 35px
        font-weight: bold
        font-size: 80%
        padding: .3rem .4rem
        .on-right
            margin: 0
