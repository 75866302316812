.q-table
  -webkit-overflow-scrolling: touch
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  tr
    th
      border:0
      font-family: 'Heebo'
      color: $darkgrey
      font-weight: 500 !important
      box-shadow: 0 -1px 0 rgba(0,0,0,.04) inset
  td
    border:0

  thead
    .q-tr
      z-index: 1000
      th
        z-index: 1000

  .q-tr
    z-index: 100
    .q-td
      z-index: 100
    &--expanded-row
      z-index: 10
      .q-td
        z-index: 10
    &--selected-row:not(&--force-no-hover)
      color: $ctrl-row-select-text !important
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) !important
      background-color: $ctrl-row-select-bg !important
    &--force-no-hover
      .q-td
        cursor: auto

:not(.q-table--grid).table-wrapper
  background-color: white
  color: $grey
  border: 1px solid #d3d3d3

.q-table--grid
  border: 1px solid #d3d3d3
  .q-table__middle
    display: none
    visibility: hidden
.q-table__grid-content
  display: grid
  grid-template-columns: repeat(auto-fit, 1fr)

.text-ellipsis
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.pagination
  .q-btn--rectangle
    padding: 0.2em 0.6rem 0.1em !important
    font-size: 12px !important
    border: 1px solid rgba(0,0,0,.08)
    border-radius: 0
    text-shadow: #fff 0 1px 0
    background-color: hsla(0,0%,100%,.15)
    cursor: pointer
  .q-icon, .material-icons
    font-size: 15px
    margin: 0.19em 0
    text-shadow: #fff 0 1px 0
    &:hover:not(.disabled)
      background-color: rgba(0,0,0,.03)

.pagination-bottom
  width: 100%
  margin-top: 2rem
  padding-bottom: 1rem

.q-table tbody tr:nth-child(2n)
  background-color: $whitesmoke

.q-table tbody tr:hover
  background-color: $ctrl-row-select-bg
  cursor: pointer

.q-table thead tr:not(.q-table__progress)
  height: 40px !important
  background-color: $ctrl-table-head

.q-table thead th:hover
  color: $primary !important

tbody tr:nth-child(2n)
  background-color: #f9f9f9
  color: $grey

.q-table-block
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

.table-block
  background-color: white
  color: $grey
  border: 1px #d3d3d3 solid

td
  border: 0
  padding: 0.5em

table
  border-spacing: 0
  width: 100%

.vcol-s, .vcol-m, .vcol-l, .vcol-xl
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  & *
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.vcol-xs
  width: 18%
  padding: 7px !important

.vcol-s
  width: 45%

.vcol-m
  width: 60%

.vcol-l
  width: 75%

.vcol-xl
  width: 90%

.vcol-xxl
  width: 140%

select
  -webkit-appearance: none

.disabled-btn
  background-color: lightgrey !important

.btn-select
  height: 20px

.btn-badge
  margin-right: -0.5rem !important
  margin-top: -0.2rem !important
  background-color: $negative

.on-right
  margin-left: 0.1rem !important

.select-pagination
  border: 1px solid rgba(0,0,0,0.24)
  padding-left: 5px
  border-radius: 3px
  max-height: 28px
  .q-field__control.relative-position.row.no-wrap
    min-height: 28px
  .q-field__native.row.items-center
    min-height: 28px
  .q-field__append.q-field__marginal.row.no-wrap.items-center.q-anchor--skip
    min-height: 28px
  .q-field__marginal
    height: 28px

.highlight
  font-weight: 700
  color: $primary

.sort-icon
  width: 30px !important

.no-padding
  padding: 0

.table-gravatar
  display: inline-block
  height: 2rem
  width: 2rem
  margin: 0 1rem 0 0
  object-fit: cover
  border-radius: 50%

.user-slot
  display: inline-flex
  vertical-align: middle

.user-inactive-bar
  border-radius: 4px
  background-color: rgba(0, 0, 0, 0.2)
  color: white
  line-height: 2rem
  padding-left: 0.5em
  padding-right: 0.5em

.q-toggle__inner--active
  color: $tertiary

// Style for clicking on the table row item.
.selected-row
  color: $ctrl-row-select-text !important
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) !important
  background-color: $ctrl-row-select-bg !important

i.counter
  font-style: normal
  border-radius: 2px
  background-color: rgba(0, 0, 0, 0.1)
  padding: .0rem .3rem

.is-empty
  .vuetable, tbody, .vuetable-empty-result
    cursor: default
    height: 100%
  td:hover
    background: white

.markup__table tbody tr:hover
  background-color: white
  cursor: default

.markup__table tbody td
  border-bottom: 1px solid $ashes

.markup__table tr th
    color: $primary

// also styling markup table
.q-table__card
  color: #878585
  background-color: white
  box-shadow: none

// Mobile styling
.q-table-mobile
  tr
    // Removes the title bar for the mobile version.
    height: 0
    th
      visibility: hidden

  tr:nth-child(2n)
    background-color: $smoke

  .q-table thead
    display: none

.mobile-style
  &__text
    color: $ctrl-side-menu-item-hover
    border-right: 1px solid $ctrl-grey-lighten-1
    padding: 2px
    padding-left: 8px
    word-break: break-all

  &__content
    color: $ctrl-darkmatter-lighten-3
    padding: 2px
    padding-left: 15px
    word-break: break-all

.table-block-card
  background-color: white
  color: $grey
  border: 1px #d3d3d3 solid

.q-inner-loading
  z-index: 2000

.q-table
  .highlight
    display: contents // should ignore presence of tag when highlighting stuff in a table
