@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'
// app global css
@import './quasar.variables'
@import './table'
@import './buttons.sass'
@import './check_width'

/* heebo-500 - latin */
@font-face
  font-family: 'Heebo'
  font-style: normal
  src: url('./fonts/heebo-v5-latin-500.eot') // IE9 Compat Modes */
  src: local('Heebo Medium'), local('Heebo-Medium'), url('./fonts/heebo-v5-latin-500.eot?#iefix') format('embedded-opentype'), url('./fonts/heebo-v5-latin-500.woff2') format('woff2'), url('./fonts/heebo-v5-latin-500.woff') format('woff'), url('./fonts/heebo-v5-latin-500.ttf') format('truetype'), url('./fonts/heebo-v5-latin-500.svg#Heebo') format('svg') // Legacy iOS */

/* dosis-regular - latin */
@font-face
  font-family: 'Dosis'
  font-style: normal
  font-weight: 400
  src: url('./fonts/dosis-v16-latin-regular.eot') // IE9 Compat Modes */
  src: local(''), url('./fonts/dosis-v16-latin-regular.eot?#iefix') format('embedded-opentype'), url('./fonts/dosis-v16-latin-regular.woff2') format('woff2'), url('./fonts/dosis-v16-latin-regular.woff') format('woff'), url('./fonts/dosis-v16-latin-regular.ttf') format('truetype'), url('./fonts/dosis-v16-latin-regular.svg#Dosis') format('svg') // Legacy iOS */

body
  font-family: 'Heebo'
  letter-spacing: 0
  font-weight: 500
  font-size: 12px
  line-height: 21px
  color: $grey
  background-color: $background

.container
  width: 100%

.full-width
  width: 100%

.left
  float: left

.right
  float: right

.centered
  vertical-align: center

.right-margin
  display: flex
  flex-direction: row-reverse
  align-items: center

.section-save
  justify-content: flex-end

.q-field__label
  font-family: 'Heebo'
  letter-spacing: 0
  font-weight: 500
  font-size: 14px
  line-height: 21px
  color: $grey

.q-field__native, .q-field__prefix, .q-field__suffix, .q-field__input, .q-select__input
  font-family: 'Heebo'
  font-weight: 500
  line-height: 21px
  letter-spacing: 0
  text-decoration: inherit
  text-transform: inherit
  border: none
  border-radius: 0
  background: none
  color: $grey
  outline: 0
  padding: 0

.async-avatar
  pointer-events: none
  background-repeat: no-repeat
  background-position: center center
  background-size: 100%
  object-fit: cover

.q-select-options
  max-height: 250px
  &--width
    max-width: 400px
